.topBar {
    height: 3.5rem;
    padding: 0 0.75rem;
    /*backdrop-filter: blur( 10px );
    -webkit-backdrop-filter: blur( 10px );*/
    background: rgba( 255, 255, 255, 0.02 );
    box-shadow: 0 0px 10px 0 rgb(0 0 0 / 37%);
    z-index: 100;
    position: relative;
}

.topBar .autocomplete-wrapper {
    width: 100%;
}

.main-sidebar.p-sidebar {
    max-width: 240px;
    overflow: auto;
}

.main-sidebar.p-sidebar .p-sidebar-header {
    display: none;
}

.main-sidebar.p-sidebar .p-sidebar-content {
    padding-top: 0.75rem !important;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
}

.p-menu {
    width: 100%;
    background: none;
    border:0px;
}

.p-menu .p-submenu-header {
    margin-top: 2rem;
    font-size: 0.75rem;
}

.p-menu .p-menuitem-link {
    padding: 0.75rem 1.5rem;
}

.p-menu .p-menuitem-link:hover {
    background-color: transparent !important;
}

.p-menu .active * {
    color: #458cff !important;
}

.p-menu .active {
    box-shadow: inset 0.25rem 0px 0px 0px #458cff;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
    margin-right: 1rem;
}

.p-menu .p-menuitem-link .p-menuitem-text {
    font-size: 0.875rem;
    color: rgba(255,255,255,0.6);
}

.sidebar-accordion .p-accordion-tab {
    box-shadow: unset;
}

.sidebar-accordion .p-accordion-header > a {
    /*The !importants are to overwrite every state*/
    padding: 0.75rem 1.5rem !important;
    background: none !important;
    color: rgba(255,255,255,0.6) !important;
    font-size: 0.875rem;
}

.sidebar-accordion .p-accordion-header .p-menuitem-icon {
    margin-right: 1rem;
}

.sidebar-accordion .p-accordion-header:hover .p-menuitem-text {
    color: rgba(255,255,255,0.87);
}

.navbar-autocomplete-panel .p-autocomplete-items {
    max-width: 100%;
}