.modesRow {
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modesRow .gamemodeRadio {
    padding: 0 max(1vw, 6px);
}

.modesRow .gamemodeRadio {
    cursor: pointer;
    font-weight: 500;
    user-select: none;
}

.modesRow .gamemodeRadio > input {
    display: none;
}

.modesRow .gamemodeRadio > input:not(:checked) + span {
    color: #999999;
}