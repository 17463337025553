.mapspage-carousel {
    padding: 0.75rem;
}

.mapspage-carousel .p-carousel-indicators .p-ink {
    display: none;
}

@media(max-width: 768px) {
    .mapspage-carousel {
        padding: 0;
    }
    .mapspage-carousel .p-carousel-indicators {
        display: none;
    }
}