.completions-card {
    background: none;
    box-shadow: none;
}

.completions-card .p-card-body {
    padding-bottom: 0;
}

.completions-card .completions-progress {
    display: flex;
    align-items: center;
    margin-bottom: 0.9rem;
}

.completions-card .completions-progress .completions-percent {
    min-width: 4rem;
}

.completions-card .completions-progress .p-progressbar {
    width: 100%;
}

.completions-card .completions-progress .completions-totals {
    min-width: 4rem;
    margin-left: 1rem;
}

@media(max-width: 768px) {
    .completions-card {
        overflow-y: auto;
    }
}