.banner-background {
    position: absolute;
    top: -3.5rem;
    left: 0;
    height: calc(100% + 3.5rem);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    opacity: 0.2;
    z-index: -1;
    filter: blur(2px);
}

@media(max-width: 768px) {
    /*BLUR AND PARALLAX HAVE LOW PERFORMANCE ON MOBILE*/
    .banner-background {
        filter: none;
        background-attachment: scroll;
    }
}