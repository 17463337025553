.hoverable-map-card {
    overflow: hidden;
    border-radius: 0.25rem;
    position: relative;
    cursor: pointer;
    display: block;
}

.hoverable-map-card .map-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform-origin: center;
    transition: transform 0.4s ease, opacity 0.4s ease;
}

.hoverable-map-card:hover .map-image {
    transform: scale(1.1);
    filter: blur(1px);
    opacity: 0.7;
}

.hoverable-map-card > .map-info {
    display: flex;
    flex-direction: column;
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 10px;
    left: 0;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
    text-shadow: 4px 4px 4px black;
    user-select: none;
    transition: opacity 0.4s ease, top 0.4s ease;
}

.hoverable-map-card:hover > .map-info {
    opacity: 100;
    top: 0;
}

.hoverable-map-card .map-info .map-name {
    color: rgba(255, 255, 255, 0.86);
    width: 100%;
    text-align: center;
}

.hoverable-map-card .map-info .mapper-name {
    font-size: 0.95rem;
    color: rgba(255, 255, 255, 0.86);
}

.map-card-description {
    margin-top: 0.5rem;
}

.map-card-description .map-tier {
    font-size: 0.9rem;
    font-weight: 300;
    text-transform: uppercase;
}

.map-card-description .map-name {
    font-size: 1.25rem;
}

.map-card-description .map-details {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: rgba(255,255,255,0.6);
}

.map-card-description .map-details .map-rating {
    padding-right: 0.571rem;
    padding-top: 0.571rem;
    padding-bottom: 0.571rem;
}

.blurry-text > .p-button-icon {
    z-index: -1; /*the scale animation of the map card makes the button icon blurry. This fixes it.*/
}