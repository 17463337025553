.p-datatable-sm {
    overflow: hidden;
}

.p-datatable-sm .p-datatable-wrapper {
    overflow: auto;
}

.p-datatable-sm .p-datatable-tbody > tr > td {
    font-size: 0.9rem;
    font-weight: 300;
    border: none;
    text-align: center;
}

.p-datatable-sm thead {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12) !important;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12) !important;
    z-index: 1;
    position: relative;
}

.p-datatable-sm .p-datatable-thead > tr > th {
    height: 3rem;
    font-size: 0.9rem;
    font-weight: 400;
    background: #272727;
    border: none;
    text-align: center !important;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover,
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover,
.p-datatable-sm .p-sortable-column.p-highlight {
    background: #272727;
}

.p-datatable-sm .p-datatable-header {
    background: #232323;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: 400;
    border: none;
    z-index: 2;
    position: relative;
}

.p-datatable-sm .p-datatable-header .tableTopItem {
    flex: 1;
    /*padding: 0 1rem;*/
}

.p-datatable-sm .p-datatable-header .p-inputtext.p-inputtext-sm{
    padding: 0.5rem 0.65625rem;
    background: transparent;
}

.p-datatable-sm .highlight-row {
    background-color: rgb(46, 46, 46) !important;
}

.p-datatable-scrollable-header-box {
    margin-right: 0 !important;
}