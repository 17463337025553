.infinite-table {
    display: flex;
    flex-direction: column;
}

.infinite-table .p-paginator {
    order: 4;
}

.infinite-table .load-more-button {
    color: #448aff;
    font-weight: 500 !important;
}