:root {
    color-scheme: dark;
}

/* roboto-300 - latin-ext_latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: local("Roboto Light"), local("Roboto-Light"), url("./fonts/roboto-v20-latin-ext-300.woff2") format("woff2"), url("./fonts/roboto-v20-latin-ext-300.woff") format("woff");
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/** {
    font-family: Roboto, sans-serif;
}*/

html {
    font-family: Roboto, sans-serif;
    margin: 0;
    padding: 0;
    font-size: 16px;
    background-color: #121212;
    color: rgba(255, 255, 255, 0.87);
}

body {
    margin: 0;
    padding: 0;
}

a {
    color: rgba(255, 255, 255, 0.87);
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}

a:not(.active):hover {
    color: #0056b3 !important;
}

.aspect-ratio-box {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
    /*box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);*/
    /*border-radius: 4px;*/
}

.aspect-ratio-box-inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

/*mdc-dark-indigo theme overwrites*/

.p-card .p-card-title {
    font-weight: 500 !important;
}

.p-button {
    font-weight: 400 !important;
}

.p-button-white {
    color: rgba(255, 255, 255, 0.87) !important;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: rgba(255, 255, 255, 0.12) !important;
    color: rgba(255, 255, 255, 0.87) !important;
    transition: .5s !important;
}

.table-link {
    background: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: color 0.2s ease-in-out;
    cursor: pointer;
    padding: 0;
    color: inherit;
}

.table-link.server-column-link {
    max-width: 100%;
    float: left;
}

.table-link:hover {
    color: #0056b3;
}

.p-field-checkbox > .p-checkbox, .p-field-radiobutton .p-radiobutton {
    margin-right: 0.5rem;
}

/*KZ RELATED*/

.points1000 {
    color: #e4ae39;
}

.points900 {
    color: #fa292e;
}

.points800 {
    color: #5d96d6;
}

.points700 {
    color: #4abc8d;
}

/* TIER */

.DEATH{
    color: #d22ce5;
}
.EXTREME{
    color: #fe0000;
}
.VERY-HARD{
    color: #fe4040;
}
.HARD{
    color: #e4ae39;
}
.MEDIUM{
    color: #ece37a;
}
.EASY{
    color: #a1fe47;
}
.VERY-EASY{
    color: #40fe40;
}

/* RANK */

.GOD{
    color: #e3ad39;
}
.DEMIGOD{
    color: #ecc166;
}
.PRO\+{
    color: #fe0000;
}
.PRO{
    color: #ff2929;
}
.SEMIPRO\+{
    color: #f9690e;
}
.SEMIPRO{
    color: #f27935;
}
.EXPERT\+{
    color: #d22ce5;
}
.EXPERT{
    color: #da5ee8;
}
.SKILLED\+{
    color: #3254fc;
}
.SKILLED{
    color: #5975ff;
}
.REGULAR\+{
    color: #5e97d8;
}
.REGULAR{
    color: #658cb9;
}
.CASUAL\+{
    color: #67809f;
}
.CASUAL{
    color: #6c7a89;
}
.TRAINEE\+{
    color: #919ca8;
}
.TRAINEE{
    color: #bdc3c7;
}
.SCRUB\+{
    color: #dadfe1;
}
.SCRUB{
    color: #eeeeee;
}
.NEWBIE\+{
    color: #ffffff;
}
.NEWBIE{
    color: #ffffff;
}

/* JUMPSTATS */

.wrecker {
    color: #d22ce5
}

.ownage {
    color :#e4ae39;
}

.godlike {
    color: #fa292e;
}

.impressive {
    color: #4abc8d
}

.perfect {
    color: #5d96d6;
}

.rgb-effect, .rgb-effect > td { /*https://codepen.io/frozen4code/pen/LBVrLN*/
    font-weight: 500 !important;
    background: linear-gradient(to right, #ff0080, #ff0080, #ff8c00, #ffff1c, #00f260, #12d8fa, #1fa2ff, #3567ff, #6100ff, #fc00ff, #ff0080, #ff0080);
    background-size: 1200%;
    animation: rgb 6s linear infinite;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
@keyframes rgb {
    0% {
        background-position: 0%;
   }
    8.3333334% {
        background-position: 8.3333334%;
   }
    16.6666667% {
        background-position: 16.6666667%;
   }
    25% {
        background-position: 25%;
   }
    33.3333334% {
        background-position: 33.3333334%;
   }
    41.6666667% {
        background-position: 41.6666667%;
   }
    50% {
        background-position: 50%;
   }
    58.3333334% {
        background-position: 58.3333334%;
   }
    66.6666667% {
        background-position: 66.6666667%;
   }
    75% {
        background-position: 75%;
   }
    83.33333334% {
        background-position: 83.33333334%;
   }
    91.6666667% {
        background-position: 91.6666667%;
   }
    100% {
        background-position: 100%;
   }
}

/*TP AND PRO TIME*/
.tp-time {
    color: #ede47a;
}

.pro-time {
    color: #4b69ff;
}