.video-gallery .video-carousel .p-carousel-next, .video-gallery .video-carousel .p-carousel-prev {
    display: none;
}

.video-gallery .video-carousel .p-carousel-indicators {
    display: none;
}

.video-gallery .map-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.video-gallery .map-image > .image-maximize {
    padding: 0.5rem;
    color: rgba(255, 255, 255, 0.6);
    transition: transform 0.3s;
    transform: translateY(2rem)
}

.video-gallery .map-image:hover > .image-maximize {
    transform: translateY(0px);
}

.video-gallery .carousel-indicators {
    padding: 1rem 0;
    display: flex;
    width: 100%;
    overflow: auto;
}

.video-gallery .carousel-indicators .carousel-indicator {
    width: 100px;
    height: 56.25px;
    margin: 0 0.25rem;
    padding: 0;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.video-gallery .carousel-indicators .carousel-indicator:hover {
    opacity: 1 !important;
}

.video-gallery .carousel-indicators .video-thumbnail-overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 32px;
    height: 32px;
    background-image: url(../../img/VideoOverlay.png);
    pointer-events: none;
    cursor: pointer;
}