.loading-moving {
    position: absolute;
    pointer-events: none;
    left: 0;
    bottom: 0;
    animation-name: walking;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes walking {
    from {left: -128px;}
    to {left: 100%;}
}