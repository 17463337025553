@media(max-width: 768px) {
    .player-panel {
        flex-direction: column;
    }

    .player-panel .picture-col {
        display: flex;
        justify-content: center;
    }

    .player-panel .picture-col .picture-div{
        margin-top: 0rem !important;
    }

    .player-panel .socials-row {
        flex-direction: column;
        margin-top: 0rem !important;
        margin-left: 2rem;
    }

    .player-panel .medals-col {
        flex-direction: row !important;
        justify-content: space-around;
        margin: 1rem 0rem !important;
    }

    .player-panel .info-col {
        margin-left: 0px !important;
    }
}

.player-panel {
    display: inline-flex;
}

.player-panel .picture-col .picture-div{
    margin-top: 1rem;
    width: 184px;
    height: 184px;
    background-color: #0E0E0E;
}

.player-panel .picture-col .avatar-frame {
    position: relative;
}

.player-panel .picture-col .avatar-frame img{
    position: absolute;
    top: -20px;
    left: -20px;
}

.player-panel .picture-col .avatar-image {
    width: 100%;
    height: 100%;
}

.player-panel .socials-row {
    display: flex;
    justify-content: space-around;
    margin-top: 1.85rem;
}

.player-panel .socials-row a {
    color: #eaeaea;
}

.player-panel .medals-col {
    margin-left: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.player-panel .medals-col .wr-badge{
    position: absolute;
    font-size: 0.75rem;
    background-color: #936e00;
    border-radius: 15%;
    padding: 0.15em 0.45em;
    top: 10px;
    left: 50px;
    user-select: none;
}

.player-panel .medals-col .red-badge{
    position: absolute;
    font-size: 0.75rem;
    background-color: #701018;
    border-radius: 15%;
    padding: 0.15em 0.45em;
    top: 0px;
    left: 34px;
    user-select: none;
}

.player-panel .medals-col .blue-badge{
    position: absolute;
    font-size: 0.75rem;
    background-color: #164f89;
    border-radius: 15%;
    padding: 0.15em 0.45em;
    top: 0px;
    left: 34px;
    user-select: none;
}

.player-panel .info-col {
    margin-left: 3rem;
    max-width: 100vw;
    overflow-x: auto;
}

.player-panel .info-col .info-name {
    /*display: flex;*/
    font-size: 5rem;
    font-weight: 300;
    margin-bottom: 0.5rem;
    color: white;
}

.player-panel .info-col .info-name span{
    display: inline-block;
}

.player-panel .info-col .info-name img{
    margin-left: 1.25rem;
}

.player-panel .info-col .info-rank {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.player-panel .info-col .info-points {
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 1rem;
    color: #999999;
}

.player-panel .info-col .info-roles {
    display: inline-flex;
    margin-bottom: 0.5rem;
}

.player-panel .info-col .info-roles .info-role {
    font-weight: 500;
    padding: 0.4rem 0.75rem;
    margin-right: 1rem;
    border-radius: 1rem;
    border: 2px solid;
}