.map-banner {
    position: relative;
}

.map-banner .banner-image {
    position: absolute;
    top: -3.5rem;
    left: 0;
    height: calc(100% + 3.5rem);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    opacity: 0.2;
    z-index: -1;
    filter: blur(2px);
}

.map-banner .map-banner-content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0rem 0.5rem;
}

.map-banner .video-gallery-wrapper {
    width: 576px;
    padding: 0rem 0.5rem;
    margin: 1.5rem 0px;
    overflow: hidden;
}

.map-banner .map-info {
    padding: 0rem 0.5rem;
    overflow: hidden;
}

.map-banner .map-info .map-description {
    display: flex;
    align-items: flex-end;
    /*flex-wrap: wrap;*/
    margin: 1.5rem 0px;
}

.map-banner .map-info .map-description .map-description-right .map-thumbnail {
    height: 130px;
    width: 231.11px;
    margin-bottom: 0.25rem; /*so it's leveled with the title and tier (144px high)*/
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.map-banner .map-info .map-description .map-description-right .map-rating {
    display: flex;
    height: 3rem;
    padding: 0 0.25rem;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
}

.map-banner .map-info .map-description .map-description-left {
    margin-left: 1.5rem;
}

.map-banner .map-info .map-description .map-description-left .map-title {
    font-size: 5rem;
    font-weight: 300;
    color: white;
}

.map-banner .map-info .map-description .map-description-left .map-tier {
    min-height: 3rem;
    font-size: 2.5rem;
    margin-left: 0.125rem; /*2px margin to compensate left white space difference with map-title because of font-size*/
    text-transform: uppercase;
}

.map-banner .map-info .map-description .map-description-left .map-mapper {
    display: flex;
    height: 3rem;
    align-items: center;
    font-weight: 500;
}

.map-banner .map-info .map-details {
    margin: 2rem 0;
    display: flex;
}

.map-banner .map-info .map-details .wr-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 3rem;
    border-right: 1px solid grey;
    flex: 1;
}

.map-banner .map-info .map-details .wr-info .wr-avatar {
    border-radius: 50%;
    display: block;
    height: 64px;
    width: 64px;
    background-color: #1E1E1E;
}

.map-banner .map-info .map-details .wr-info .wr-name {
    font-size: 1.25rem;
    white-space: nowrap;
}

.map-banner .map-info .map-details .wr-info .wr-name > a {
    max-width: 11.45rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: text-bottom;
}

.map-banner .map-info .map-details .wr-info .wr-details {
    color: rgba(255, 255, 255, 0.6);
    white-space: nowrap;
}

.map-banner .map-info .map-details .pb-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 3rem;
    border-right: 1px solid grey;
    flex: 1;
}

.map-banner .map-info .map-details .pb-info .pb-medal {
    margin-left: 3rem;
}

.map-banner .map-info .map-details .map-api-info {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0rem 0.25rem 3rem;
    flex: 1;
    white-space: nowrap;
}

.map-banner .map-info .map-details .extra-functionality-button {
    color: var(--primary-color);
    background: none;
    border: none;
    margin-right: 0.25rem;
    cursor: pointer;
}

.map-banner .map-info .map-details .map-api-info .workshop-button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

@media(max-width: 992px) {
    /*BLUR AND PARALLAX HAVE LOW PERFORMANCE ON MOBILE*/
    .map-banner .banner-image {
        filter: none;
        background-attachment: scroll;
    }

    .map-banner .map-info .map-details {
        flex-wrap: wrap;
        justify-content: center;
    }

    .map-banner .map-info .map-description {
        justify-content: center;
    }

    .map-banner .map-info .map-details .wr-info {
        padding: 0;
        border: 0;
        flex: none;
        width: 100%;
        margin-bottom: 2rem;
    }

    .map-banner .map-info .map-details .pb-info {
        padding: 0.25rem 3rem 0.25rem 0rem;
        flex: none;
        padding-right: clamp(1rem, 4vw, 3rem);
    }

    .map-banner .map-info .map-details .pb-info .pb-medal {
        margin-left: clamp(1rem, 4vw, 3rem);
    }

    .map-banner .map-info .map-details .map-api-info {
        padding: 0.25rem 0rem 0.25rem 3rem;
        flex: none;
        padding-left: clamp(1rem, 4vw, 3rem);
    }
}

@media(max-width: 768px) {
    .map-banner .map-info .map-description {
        display: block;
    }

    .map-banner .map-info .map-description .map-description-right {
        display: flex;
        flex-wrap: wrap;
    }

    .map-banner .map-info .map-description .map-description-right .map-thumbnail {
        width: 100%;
        padding-top: 56.25%;
    }

    .map-banner .map-info .map-description .map-description-left {
        margin-left: 0;
    }

    .map-banner .map-info .map-description .map-description-left .map-title {
        font-size: clamp(3rem, 8.3vw, 5rem);
        margin-bottom: 0.5rem;
    }

    .map-banner .map-info .map-description .map-description-left .map-tier {
        min-height: 0;
        font-size: clamp(2rem, 5.4vw, 2.5rem);
    }
}

.map-route-content {
    margin-top: 1rem;
    min-height: 100vh;
    overflow: auto;
}

@media(max-width: 576px) {
    .map-banner .map-info {
        width: 100%;
    }

    .map-banner .map-info .map-description .map-description-left .map-title {
        font-size: clamp(1rem, 10vw, 3rem);
        margin-bottom: 0.5rem;
    }

    .map-banner .map-info .map-description .map-description-left .map-tier {
        font-size: clamp(1rem, 7vw, 2rem);
    }

    .map-banner .map-info .map-details {
        display: block;
    }

    .map-banner .map-info .map-details .wr-info {
        padding: 0;
        border: none;
    }

    .map-banner .map-info .map-details .pb-info {
        padding: 0;
        border: none;
        justify-content: space-between;
        margin-top: 2rem;
    }

    .map-banner .map-info .map-details .pb-info .pb-medal {
        display: none;
    }

    .map-banner .map-info .map-details .map-api-info {
        padding: 0;
        justify-content: space-between;
        margin-top: 2rem;
    }
}