.leaderboard-banner {
    width: 100%;
    display: block;
    position: relative;
}

.leaderboard-banner .leaderboard-banner-wrapper {
    position: relative;
    width: 100%;
    min-height: 128px;
    display: block;
    overflow-x: hidden; /*so moving loading doesn't show scrollbar on exit*/
}

/*Need to set background so the shadows from the cards don't leak down*/
.leaderboard-body {
    width: 100%;
    min-height: 100vh;
    background-color:#121212;
}

.jumptype-navlink {
    padding-right: max(1vw, 6px);
    padding-left: max(1vw, 6px);
    font-weight: 500;
}

.jumptype-navlink.active:hover {
    color: rgba(255, 255, 255, 0.86) !important;
}

.jumptype-navlink:not(.active) {
    color: #999999 !important;
}

.jumptype-dropdown {
    background: none;
    padding-right: max(2vw, 12px);
    border: none;
    box-shadow: none !important;
}

.jumptype-dropdown > span {
    font-weight: 500;
}

.leaderboard-body .leaderboard-selector {
    width: 280px;
    display: flex;
    justify-content: center;
}

.leaderboard-body .leaderboard-selector input {
    display: none;
}

.leaderboard-body .leaderboard-selector label {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #999999;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.2rem;
    user-select: none;
    cursor: pointer;
    position: relative;
    padding: 1rem;
}

.leaderboard-body .leaderboard-selector input:checked + label {
    color: #FFFFFFDE;
    font-weight: 700;
}

.leaderboard-body .leaderboard-selector > input:checked + label::after {
    /*mas facil hacer un div especifico para esto*/
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform-origin: center;
    border-bottom: 4px solid #448aff;
    animation-name: grow-middle;
    animation-duration: 0.2s;
    animation-timing-function: ease;
}

@keyframes grow-middle {
    from {transform: scale3d(0,1,1);}
    to {transform: scale3d(1,1,1);}
}

.minimal-datatable .p-datatable-thead > tr > th {
    background: transparent;
    border: none;
}

.minimal-datatable .p-datatable-tbody > tr {
    background: transparent;
}

.minimal-datatable .p-datatable-tbody > tr > td {
    border: none;
}

.minimal-datatable .p-datatable-tbody > tr > td img{
    display: block;
}