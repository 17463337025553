.playerBackground {
    position: relative;
}

.tab-menu {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.tab-menu .tab-menu-item .active {
    box-shadow: inset 0px -0.25rem 0px 0px #448AFF;
}

.tab-menu .tab-menu-item .tab-menu-link.active {
    color: #448AFF;
}

.tab-menu .tab-menu-item .tab-menu-link{
    display: flex;
    padding: 0.75rem 1.25rem;
    text-decoration: none;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
}

.tab-menu .tab-menu-item .tab-menu-link .tab-menu-icon {
    margin-right: 0.5rem;
}

.banner-panel {
    width: 576px;
}

.information-grid {
    display: grid;
    /*grid-template-rows: repeat(6, calc(100vw/6/1.98));*/
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: calc(100vw/6/1.98);
    grid-auto-flow: row dense;
    gap: calc(100vw/120);
    padding: calc(100vw/120);
}

@media(max-width: 768px) {
    .tab-menu {
        justify-content: space-between;
    }

    .tab-menu .tab-menu-item .tab-menu-link .tab-menu-icon {
        margin-right: 0rem;
    }
}