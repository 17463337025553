/*Responsive: avatar 80px, placing 150px, name 1.5rem, points 1.5rem, sizes 290 250 210, banner-wrapper 362*/

.l-card-container {
    width: 248px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 0px #000000; 
    box-shadow: 0px 0px 20px 0px #000000;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.l-card-container .l-avatar-background {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: -1;
    filter: blur(1px);
}

.l-card-container .l-background-gradient {
    position: absolute;
    width: inherit;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(0deg, rgba(18,18,18,1) 5%, rgba(18,18,18,0.5) 100%);
}

.l-card-container .l-placing {
    width: clamp(125px, 30vw, 200px);
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.l-card-container .l-avatar {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.l-card-container .l-avatar .l-avatar-img {
    width: 100%;
    max-width: 136px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 20px 0px #000000; 
    box-shadow: 0px 0px 20px 0px #000000;
    transition: 250ms;
}

.l-card-container .l-avatar > a:hover .l-avatar-img {
    transform: scale(1.1);
    -webkit-box-shadow: 0px 0px 30px -5px #000000; 
    box-shadow: 0px 0px 30px -5px #000000;
}

.l-card-container .l-avatar > a {
    max-width: 100%;
    border-radius: 50%;
}

.l-card-container .l-name {
    font-size: clamp(1.25rem, 4vw, 1.75rem) ;
    font-weight: 500;
}

.l-card-container .l-score {
    font-weight: 500;
}