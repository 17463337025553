.dataview-maps {
    min-height: 100vh;
    padding-left: 3rem;
    padding-right: 1.75rem;
}

.dataview-maps .p-dataview-content, .dataview-maps .p-paginator, .dataview-maps .p-dataview-header {
    background: none;
}

.dataview-maps .p-paginator {
    border: none;
}

.dataview-maps .p-dataview-header {
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.dataview-maps .p-dataview-content .p-grid {
    justify-content: center;
}

.dataview-maps .dataview-card {
    padding: 2rem 0.25rem;
}

.map-dataview-dropdown {
    width: 100%;
    min-width: 70px;
    max-width: 210px;
    margin-right: 0.5rem;
}

.map-dataview-dropdown .p-dropdown-label {
    /*values of class p-inputtext-sm */
    font-size: 0.875rem;
    padding: 0.65625rem 0.65625rem;
}

.stylized-navlink {
    display: inline-block;
    width: 50%;
    text-align: center;
    color: #999999;
    font-size: clamp(0.9rem, 4vw, 1.25rem);
    font-weight: 600;
    letter-spacing: 0.2rem;
    user-select: none;
    padding: 1rem;
}

.stylized-navlink.active {
    color: #FFFFFFDE;
    font-weight: 700;
    border-bottom: 4px solid #448aff;
}

.stage-selector label {
    position: relative;
    width: 50%;
    text-align: center;
    padding: 0.5rem;
    cursor: pointer;
}

.stage-selector > input {
    display: none;
}

.stage-selector > input:checked + label::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform-origin: center;
    border-bottom: 6px solid var(--primary-color);
    animation-name: grow-middle;
    animation-duration: 0.2s;
    animation-timing-function: ease;
}

.secondary-comment {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    margin-bottom: 1rem;
}

.mask-transition {
    animation: fadein .2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media(max-width: 992px) {
    .dataview-maps {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}